exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-ai-development-company-jsx": () => import("./../../../src/pages/services/AI-development-company.jsx" /* webpackChunkName: "component---src-pages-services-ai-development-company-jsx" */),
  "component---src-pages-services-android-development-company-jsx": () => import("./../../../src/pages/services/android-development-company.jsx" /* webpackChunkName: "component---src-pages-services-android-development-company-jsx" */),
  "component---src-pages-services-angular-development-company-jsx": () => import("./../../../src/pages/services/angular-development-company.jsx" /* webpackChunkName: "component---src-pages-services-angular-development-company-jsx" */),
  "component---src-pages-services-bot-development-company-jsx": () => import("./../../../src/pages/services/bot-development-company.jsx" /* webpackChunkName: "component---src-pages-services-bot-development-company-jsx" */),
  "component---src-pages-services-cloud-development-company-jsx": () => import("./../../../src/pages/services/cloud-development-company.jsx" /* webpackChunkName: "component---src-pages-services-cloud-development-company-jsx" */),
  "component---src-pages-services-custom-web-development-service-jsx": () => import("./../../../src/pages/services/custom-web-development-service.jsx" /* webpackChunkName: "component---src-pages-services-custom-web-development-service-jsx" */),
  "component---src-pages-services-data-services-company-jsx": () => import("./../../../src/pages/services/data-services-company.jsx" /* webpackChunkName: "component---src-pages-services-data-services-company-jsx" */),
  "component---src-pages-services-enterprise-software-solutions-jsx": () => import("./../../../src/pages/services/enterprise-software-solutions.jsx" /* webpackChunkName: "component---src-pages-services-enterprise-software-solutions-jsx" */),
  "component---src-pages-services-hire-ionic-developer-jsx": () => import("./../../../src/pages/services/hire-ionic-developer.jsx" /* webpackChunkName: "component---src-pages-services-hire-ionic-developer-jsx" */),
  "component---src-pages-services-i-os-development-company-jsx": () => import("./../../../src/pages/services/iOS-development-company.jsx" /* webpackChunkName: "component---src-pages-services-i-os-development-company-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-laravel-development-company-jsx": () => import("./../../../src/pages/services/laravel-development-company.jsx" /* webpackChunkName: "component---src-pages-services-laravel-development-company-jsx" */),
  "component---src-pages-services-mobile-app-development-company-jsx": () => import("./../../../src/pages/services/mobile-app-development-company.jsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-company-jsx" */),
  "component---src-pages-services-nodejs-development-company-jsx": () => import("./../../../src/pages/services/nodejs-development-company.jsx" /* webpackChunkName: "component---src-pages-services-nodejs-development-company-jsx" */),
  "component---src-pages-services-python-development-company-jsx": () => import("./../../../src/pages/services/python-development-company.jsx" /* webpackChunkName: "component---src-pages-services-python-development-company-jsx" */),
  "component---src-pages-services-react-js-developer-jsx": () => import("./../../../src/pages/services/react-js-developer.jsx" /* webpackChunkName: "component---src-pages-services-react-js-developer-jsx" */),
  "component---src-pages-services-reactnative-app-development-company-jsx": () => import("./../../../src/pages/services/reactnative-app-development-company.jsx" /* webpackChunkName: "component---src-pages-services-reactnative-app-development-company-jsx" */),
  "component---src-pages-services-security-services-company-jsx": () => import("./../../../src/pages/services/security-services-company.jsx" /* webpackChunkName: "component---src-pages-services-security-services-company-jsx" */),
  "component---src-pages-services-vuejs-developers-jsx": () => import("./../../../src/pages/services/vuejs-developers.jsx" /* webpackChunkName: "component---src-pages-services-vuejs-developers-jsx" */),
  "component---src-pages-services-web-design-development-service-jsx": () => import("./../../../src/pages/services/web-design-development-service.jsx" /* webpackChunkName: "component---src-pages-services-web-design-development-service-jsx" */),
  "component---src-pages-services-web-development-companies-in-india-jsx": () => import("./../../../src/pages/services/web-development-companies-in-india.jsx" /* webpackChunkName: "component---src-pages-services-web-development-companies-in-india-jsx" */),
  "component---src-pages-technology-jsx": () => import("./../../../src/pages/technology.jsx" /* webpackChunkName: "component---src-pages-technology-jsx" */),
  "component---src-templates-blog-list-by-category-jsx": () => import("./../../../src/templates/BlogListByCategory.jsx" /* webpackChunkName: "component---src-templates-blog-list-by-category-jsx" */),
  "component---src-templates-blog-list-by-tag-jsx": () => import("./../../../src/templates/BlogListByTag.jsx" /* webpackChunkName: "component---src-templates-blog-list-by-tag-jsx" */),
  "component---src-templates-blog-view-jsx": () => import("./../../../src/templates/BlogView.jsx" /* webpackChunkName: "component---src-templates-blog-view-jsx" */),
  "component---src-templates-v-card-jsx": () => import("./../../../src/templates/VCard.jsx" /* webpackChunkName: "component---src-templates-v-card-jsx" */)
}

